import React, { Component } from "react";

import photo2 from "../../../assets/photo2.jpg";
//import karl from "../../../assets/Karl.png";
import steve from "../../../assets/Steve_Y.jpg";
import jason from "../../../assets/Jason_S.jpg";
//import jacob from "../../../assets/Jacob.png";
//import male from '../../../assets/people/Male.png'
//import female from '../../../assets/people/Female.png'
import Person from "./Person";

class WhoWeAre extends Component {
	render() {
		return (
			<section>
				<div
					id="top-content-region"
					className="top-content padding-top-15 padding-bottom-15 block-15 bg-color-grayLight1"
				>
					<div className="container">
						<div className="row">
							<div id="top-content-left-region" className="top-content-left col-xs-12 col-md-6 text-center-sm">
								<div id="page-title-block" className="page-title block">
									<h1>Who We Are</h1>
								</div>
							</div>

							<div
								id="top-content-right-region"
								className="top-content-right col-xs-12 col-md-6 text-right text-center-sm"
							>
								<div id="page-breadcrumbs-block" className="page-breadcrumbs block">
									<div className="breadcrumbs">
										<a href="/">Home</a>
										<span className="delimiter">›</span>
										<span title="" className="nolink">
											About
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div id="content-region">
					<div className="container">
						<div className="row">
							<div id="main-content-region" className="main-content col-xs-12">
								<div className="region region-content">
									<div id="block-system-main" className="block block-system">
										<div className="content">
											<div className="node node-page clearfix">
												<div className="content threeFourth clearfix">
													<div className="clearfix post-326 page type-page status-publish hentry">
														<div className="mobile-only text-center">
															<img
																className="img img-responsive"
																src={photo2}
																alt="Sunday Morning"
																width="400"
																height="299"
															/>
														</div>
														<p className="text-justify">
															<img
																className="alignright desktop-only"
																src={photo2}
																alt="Sunday Morning"
																width="370"
																height="266"
															/>
															<strong>The Branch Christian Church</strong> officially started in 1999. We moved into our
															facilities in Kings Meadows in 2006.
														</p>
														<p className="text-justify">
															All of us are just normal people who’ve discovered the good news about what God has done
															through Jesus.
														</p>
														<p className="text-justify">
															The Branch is part of the{" "}
															<a href="http://www.fiec.org.au/" target="_blank" rel="noreferrer noopener">
																Fellowship of Independent Evangelical Churches
															</a>{" "}
															(FIEC) and we are also connected with{" "}
															<a href="https://vision100.org/" target="_blank" rel="noreferrer noopener">
																Vision 100
															</a>
															, a leadership training and church planting network within Tasmania. We also appreciate
															and support the work of groups like{" "}
															<a href="https://genevapush.com/" target="_blank" rel="noreferrer noopener">
																Geneva Push
															</a>{" "}
															and{" "}
															<a
																href="https://australia.thegospelcoalition.org/"
																target="_blank"
																rel="noreferrer noopener"
															>
																The Gospel Coalition Australia
															</a>
															.
														</p>
														<h2 className="text-justify">Staff</h2>
														<p className="text-justify">
															We have range of people from the church who, as well as their day to day job, have
															leadership and outreach responsibilities in the church. 
														</p>
													
														<div className="row col-xs-12">
															<Person
																align="left"
																image={steve}
																blurb="<strong>Steve Young</strong> is our Senior Pastor. He was called in early 2024 and has since moved down from sunny Brisbane to help lead the church in maturity and loving obedience to Christ. He has a passion for the faithful teaching of God's Word that challenges the heart, and a desire to see people from all walks to life to come to know Jesus as their only hope in life and death. He is married to Annie and has two primary-aged children.</p>"
															/>
														</div>

														<div className="row col-xs-12">
															<Person
																align="left"
																image={jason}
																blurb="<strong>Jason Summers</strong> is our Associate Pastor serving in a part time capacity.  He has lived in Tasmania for the past 14 years with his wife Elizabeth and their 4 children.  Jason's ministry focus is pastoral care and prayer through which he is seeking to lead the lost, lonely and suffering to Christ.</p>"
															/>
														</div>
														
														<div className="row col-xs-12">
															<br />
															<br />
															<p className="text-justify">
																<strong>If you would like to know more</strong> about Jesus Christ or our church please
																don’t hesitate to{" "}
																<strong>
																	<a
																		href="https://thebranch.elvanto.com.au/form/8a95e7d4-4b16-11e3-963d-e9c9319b7782"
																		target="_blank"
																		rel="noreferrer noopener"
																	>
																		get in touch
																	</a>
																</strong>{" "}
																and we would be happy to send you an information pack. Otherwise you can drop in an{" "}
																<strong>
																	<a href="/Visit">visit us</a>
																</strong>
																.
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>{" "}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default WhoWeAre;
